export const firebaseConfig = {
  apiKey: "AIzaSyBdt44mkpPCqRsLSx40dfYLXbzg_MgydTM",
  authDomain: "glassolutionsrackpickup.firebaseapp.com",
  databaseURL: "https://glassolutionsrackpickup-9810f.europe-west1.firebasedatabase.app/", // PROD
  //databaseURL: "https://glassolutionsrackpickup-test.europe-west1.firebasedatabase.app/", // DEV
  projectId: "glassolutionsrackpickup",
  storageBucket: "glassolutionsrackpickup.appspot.com",
  messagingSenderId: "989833300909",
  appId: "1:989833300909:web:7a1fa761faef56604eef24",
  measurementId: "G-6W917W30TG",
};