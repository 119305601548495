import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/scan",
    name: "Scan",
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Scan.vue"),
  },

  {
    path: "/register",
    name: "Register",
    component: () =>
      import(/* webpackChunkName: "details" */ "../views/Register.vue"),
  },
  {
    path: "/location",
    name: "Location",
    component: () =>
      import(/* webpackChunkName: "location" */ "../views/Location.vue"),
  },
  {
    path: "/order",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/Order.vue"),
  },
  {
    path: "/receipt",
    name: "Receipt",
    component: () =>
      import(/* webpackChunkName: "receipt" */ "../views/Receipt.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/Admin.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/help",
    name: "Help",
    component: () => import(/* webpackChunkName: "help" */ "../views/Help.vue"),
  },
  {
    path: "/update",
    name: "Content",
    component: () =>
      import(/* webpackChunkName: "content" */ "../views/Content.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to) => {
  // eslint-disable-next-line no-undef
  gtag("event", "page_view", {
    page_path: to.fullPath,
    page_title: to.name,
  });
});

export default router;
