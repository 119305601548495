<template>
  <router-link to="/" class="logo"></router-link>
  <router-view />
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Footer,
  },
  data: function () {
    return {};
  },
  methods: {
    showCookieBanner() {
      if (window.Cookiebot) {
        //console.log(window.Cookiebot);
        if (!window.Cookiebot.consent.necessary) {
          window.Cookiebot.show();
        }
      }
    },
  },
  mounted() {
    fetch("https://consent.cookiebot.com/059b6d67-3226-4de6-ae9e-093611455943/cd.js"
    ).then(() => this.showCookieBanner());

  },
};
</script>

<style lang="scss">
@import "@/styles/app.scss";
</style>

<style></style>
