import { createStore } from "vuex";

export default createStore({
  state: {
    cookieName: "userInfo",
  },
  mutations: {},
  actions: {},
  modules: {},
});
