<template>
  <div class="register">
    <div class="register__text">
      Bestil afhentning af SCANGLAS stel<br />
      - nemt og hurtigt med din mobiltelefon<br /><br />
      Bemærk at stel skal være tomme, i modsat fald kontakt salgsafdelingen.
    </div>
    <div class="cta">
      <div class="buttons single">
        <button class="button" tabindex="1" @click="registerRacks()">
          <i class="fas fa-truck"></i>
          {{ msg }}
        </button>
      </div>
      <router-link class="link--scan" to="/scan">Scan kode</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationType",

  props: {
    msg: String,
  },
  data: function() {
    return {};
  },
  methods: {
    scanCode() {
      this.$router.push("/scan");
    },
    registerRacks() {
      this.$router.push("/register");
    },
  },
  mounted() {
    this.$store.state.codes = null;
    this.$store.state.location = null;
    this.$store.state.comment = null;
    this.$store.state.phone = null;
    this.$store.state.email = null;
    this.$store.state.extraA = null;
    this.$store.state.extraL = null;
  },
};
</script>

<style scoped lang="scss">
.register {
  &__text {
    text-align: center;
    margin: 0 auto;
  }
}
.cta {
  margin-top: 2rem;
}
.link--scan {
  text-align: center;
  display: block;
  color: #2C4390;
}
</style>
