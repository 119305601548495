import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//FIREBASE
import { firebaseConfig } from "./firebaseConfig";
import firebase from "firebase/app";
import "firebase/auth";

//COOKIES
import { VueCookieNext } from "vue-cookie-next";

//PRIMEVUE
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Calendar from "primevue/calendar";

import "primevue/resources/primevue.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css";

createApp(App)
  .use(store)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("InputText", InputText)
  .component("Dropdown", Dropdown)
  .component("Button", Button)
  .component("Calendar", Calendar)
  .use(router)
  .use(VueCookieNext)
  .mount("#app");

firebase.initializeApp(firebaseConfig);

// set default config
VueCookieNext.config({ expire: "1d" });
