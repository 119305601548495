<template>
  <div class="footer">
    <router-link to="/help">Hjælp</router-link>

    <a href="https://glassolutions.dk/da/privacy-policy" target="_blank"
      >Privatlivspolitik</a
    >
    <router-link class="dt-only" to="/admin">Admin</router-link>

    <div class="footer__logo">
      <img src="../assets/stgobain.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",

  props: {
    msg: String,
  },
  data: function() {
    return {
      loggedIn: false,
    };
  },
  methods: {},
  mounted() {
    this.loggedIn = this.$store.state.loggedIn;
  },
};
</script>

<style scoped lang="scss">
.footer {
  z-index: 3;
  padding: 1rem 0;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  a {
    padding: 0 1rem;
    color: #636466;
    text-decoration: none;
    border-right: 1px solid #e6e6e6;
    &:last-child {
      border-right: none;
    }
  }
  &__logo {
    visibility: hidden;
    position: absolute;
    right: 1rem;
    bottom: 0;
    img {
      width: 120px;
    }
  }
}
</style>
